<template>
  <div>
    <h1>Create New Coupon</h1>
    <form
      class="coupons__form fc-container-sm fc-flex fc-column fc-align-center">
      <FCInput
        v-model="name"
        class="custom-spen__name-input"
        type="text"
        title="Coupon Code"
        name="Name"
        :value="name"
        :upper-case-only="true"
        @keypress="isLetterOrNumber($event)" />
      <div class="coupons__price-off-container fc-flex">
        <FCInput
          v-model="amountOff"
          class="custom-spen__amount-off-input"
          type="number"
          title="Amount Off i/e 50 = $50"
          name="Amount Off"
          :loading="isAmountOffSDisabled"
          :error="checkAmountOffError"
          @keypress="isNumber($event)" />
        <h2 class="fc-margin-x-4">
          OR
        </h2>
        <FCInput
          v-model="percentOff"
          class="custom-spen__percent-off-input"
          type="number"
          title="Percent Off i/e. 25 = 25%"
          name="Percent Off"
          :error="checkPercentOffError"
          :loading="isPercentOffDisabled" />
      </div>
      <multiselect
        v-model="itemOptionSelected"
        class="custom-spen__items-applied-input"
        :options="itemOptions"
        placeholder="Pick some thangs to apply the coupon to"
        :multiple="true"
        :close-on-select="true"
        track-by="name"
        label="name" />
      <br>
      <multiselect
        v-model="itemOptionRestricted"
        class="custom-spen__items-restricted-input"
        :options="itemOptions"
        placeholder="Pick some thangs that DO NOT apply"
        :multiple="true"
        :close-on-select="true"
        :disabled="isSubscriptionOffCheck"
        track-by="name"
        label="name" />
      <br>
      <div class="coupons__redeem-containter fc-flex">
        <Checkbox
          id="redeem-check"
          v-model="isRedeemByCheck"
          class="coupons__redeem-checkbox" />
        <h2 class="coupons__redeem-text fc-margin-y-0 fc-margin-x-4">
          Redeem By:
        </h2>
        <v-date-picker
          v-if="isRedeemByCheck"
          v-model="redeemBy"
          class="coupons__calendar fc-margin-x-4"
          :class="{'coupons__calendar--active': isRedeemByCheck}"
          color="red" />
      </div>
      <div class="coupons__subscription-off-container fc-flex">
        <Checkbox
          id="redeem-check"
          v-model="isSubscriptionOffCheck" />
        <h2 class="coupons__subscription-off-title fc-margin-y-0 fc-margin-x-4">
          For Subscriptions Only:
        </h2>
        <FCSelect
          v-if="isSubscriptionOffCheck"
          v-model="subscriptionAmountOff"
          class="coupons__subscription-off-select"
          :options="subscriptionAmountOffOptions"
          label="Months off" />
      </div>
      <ButtonAction
        v-show="!success"
        primary
        :disabled="loading && !error"
        class="coupons__submit-button"
        @click.native="createCoupon">
        Submit
      </ButtonAction>
    </form>
    <div v-show="success" class="coupons__success-button-container fc-flex">
      <ButtonAction
        class="coupons__success-button"
        @click.native="reset">
        Success! (Press to reset)
      </ButtonAction>
    </div>
    <p v-if="error" class="coupons__error fc-text-red fc-bold">
      {{ error }}
    </p>
    <p
      class="coupons__coupon-data fc-container-sm">
      {{ getCouponData }}
    </p>
    <br><br><br>
    <iframe
      class="airtable-embed coupons__coupon-table"
      src="https://airtable.com/embed/shrHVxpbM50c5yPRE?backgroundColor=gray"
      frameborder="0"
      onmousewheel=""
      width="80%"
      height="533"
      style="background: transparent; border: 1px solid #ccc;" />
  </div>
</template>

<script>
  import axios from 'axios';
  import Multiselect from 'vue-multiselect';
  import { FCInput, Checkbox, FCSelect } from '@fc/angie-ui';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import couponItems from '@/shared/datasets/coupon_items.json';

  export default {
    components: {
      FCInput,
      ButtonAction,
      Multiselect,
      Checkbox,
      FCSelect,
    },
    data() {
      return {
        itemOptions: couponItems,
        itemOptionSelected: null,
        itemOptionRestricted: null,
        name: '',
        amountOff: '',
        percentOff: '',
        loading: false,
        success: false,
        isRedeemByCheck: false,
        redeemBy: new Date(),
        error: '',
        isAmountOffError: false,
        isPercentOffError: false,
        subscriptionAmountOff: '',
        subscriptionAmountOffOptions: [{ key: -3900, name: 'one month' }, { key: -7800, name: '2 months' }],
        isSubscriptionOffCheck: false,
      };
    },
    computed: {
      isDefaultAmoutOffValue() {
        if (this.isSubscriptionOffCheck && this.percentOff) {
          return false;
        }
        return this.isSubscriptionOffCheck && this.amountOff.length === 0;
      },
      isAmountOffSDisabled() {
        return this.percentOff.length > 0;
      },
      checkAmountOffError() {
        return this.amountOff > 5000;
      },
      isPercentOffDisabled() {
        return this.amountOff.length > 0;
      },
      checkPercentOffError() {
        return this.percentOff > 80;
      },
      getCouponData() {
        const couponData = {
          name: this.name.toUpperCase(),
          amountOff: this.isDefaultAmoutOffValue ? 0.01 : this.amountOff,
          percentOff: this.percentOff ? Number.parseFloat(this.percentOff).toFixed(1) : 0.0,
          redeemBy: this.isRedeemByCheck ? this.redeemBy.toString() : '',
          redeemByUnix: this.isRedeemByCheck ? Math.floor((this.redeemBy).getTime() / 1000) : '',
          itemCheck: this.itemOptionSelected ? this.itemOptionSelected.map(option => option.key).join(',') : '',
          itemRestricted: this.itemOptionRestricted ? this.itemOptionRestricted.map(option => option.key).join(',') : '',
          subscriptionCreditMonths: this.isSubscriptionOffCheck ? Number(this.subscriptionAmountOff) : '',
          obfuscated: true,
          username: this.$auth.user.name,
        };

        return couponData;
      },
    },
    methods: {
      areFieldsEmpty(couponObj) {
        const amountOrPercentageOffError = !this.isSubscriptionOffCheck && (!this.isAmountOffSDisabled && !this.isPercentOffDisabled);
        const subscriptionCreditMonthsError = this.subscriptionAmountOff === '' && this.isSubscriptionOffCheck;
        const couponCode = couponObj.name === '';

        return couponCode || amountOrPercentageOffError || subscriptionCreditMonthsError;
      },
      async createCoupon() {
        this.loading = true;
        const coupon = this.getCouponData;

        if (this.areFieldsEmpty(coupon)) {
          this.error = 'One or more fields are empty. Please try again.';
        } else {
          await Promise.all([
            axios.post('/.netlify/functions/createStripeCoupon', { coupon }),
            axios.post('/.netlify/functions/createAirtableEntry', { coupon }),
          ]).then(() => {
            this.success = true;
          }).catch(() => {
            this.error = 'Something went wrong. Please try again.';
          });
        }
      },
      reset() {
        this.redeemBy = new Date();
        this.name = '';
        this.amountOff = '';
        this.percentOff = '';
        this.itemOptionSelected = null;
        this.itemOptionRestricted = null;
        this.success = false;
        this.loading = false;
      },
      isLetterOrNumber(e) {
        const char = String.fromCharCode(e.keyCode);
        if ((/^[A-Za-z0-9]+$/.test(char)) && this.name.length < 15) return true;
        return e.preventDefault();
      },
      isNumber(e) {
        const char = String.fromCharCode(e.keyCode);
        if (/^[0-9]+$/.test(char)) return true;
        return e.preventDefault();
      },
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  @import '@fc/angie-ui/dist/styles/exports';

  .coupons {
    &__price-off-container, &__redeem-containter {
      width: 100%;
    }

    &__calendar {
      &--active {
        margin-bottom: get-spacing-level(8);
      }
    }

    &__subscription-off-container {
      width: 100%;
      margin: get-spacing-level(4) get-spacing-level(0);
    }

    &__subscription-off-title {
      text-align: left;
      width: 70%;
    }

    &__success-button {
      background-color: $color-system-green;
      border: none;
    }

    &__coupon-data {
      font-size: get-font-size-level(7);
      margin-top: get-spacing-level(4);
    }

    &__coupon-table {
      margin: get-spacing-level(4);
    }
  }
</style>
